import { api } from "services";

/**
 * Envia um contato para o suporte
 * @param name
 * @param phone
 * @param email
 * @param msg
 * @return {Promise}
 */
export const sendContact = ({ name, phone, email, msg }) =>
  api.post("project-site/send-contact", { name, phone, email, msg });

/**
 *
 * @param name
 * @param phone
 * @param site
 * @param contact_with
 * @param best_time
 * @return {Promise}
 */
export const consultantsContact = ({
  name,
  phone,
  site,
  contact_with,
  best_time
}) =>
  api.post("project-site/consultants-contact", {
    name,
    phone,
    site,
    contact_with,
    best_time
  });
