import styled, { css } from "styled-components";
// Styles
import { Content } from "sections/styles";

export const Wrapper = styled.div`
  padding: 60px 20px;
  width: 100%;
  background-color: #0b2037;

  ${Content} {
    display: flex;

    @media all and (max-width: 40em) {
      flex-direction: column;
      margin-top: -25px;
    }
  }
`;

export const Box = styled.div`
  ${({ grow }) =>
    grow &&
    css`
      flex-grow: 1;
    `}

  ${({ shrink }) =>
    shrink &&
    css`
      flex-shrink: 0;
      min-width: 0;
    `};

  @media all and (min-width: 40em) {
    & + & {
      margin-left: 40px;
    }
  }

  @media all and (max-width: 40em) {
    margin-top: 25px;
  }
`;

export const BoxPhones = styled(Box)`
  max-width: 420px;

  a {
    color: #fff;

    svg {
      margin-right: 0.3em;
    }
  }
`;

export const Title = styled.h3`
  color: #fff;
  font-size: 16px;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #fff;
`;

export const List = styled.ul`
  color: #fff;
  display: flex;
  flex-wrap: wrap;

  svg {
    vertical-align: middle;
  }

  li {
    line-height: 30px;
    min-width: 203px;
    white-space: nowrap;
    flex: 1 0 50%;
    width: 50%;
  }
`;

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;
`;
