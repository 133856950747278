import React, { useState } from "react";
import { Formik, Field, FastField } from "formik";

// Components
import Button from "components/Button";
import FormWrapper from "components/FormWrapper";
import { ColLarge, ColMedium } from "components/FormWrapper/styles";
import { Input, Select, InputMasked, ButtonGroup } from "components/Form";

// Services
import { consultantsContact } from "services/contact";

// Helpers
import { PHONE_MASK } from "helpers/form-helpers";

// Styles
import { Row } from "components/Grid";

function FormSpecialConditions() {
  const [canShowMessage, setMessage] = useState(false);

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        site: "",
        contact_with: 1,
        best_time: "14:00"
      }}
      validate={values => {
        let errors = {};

        if (!values.name) {
          errors.name = "Campo obrigatório";
        }

        if (!values.phone) {
          errors.phone = "Campo obrigatório";
        }

        return errors;
      }}
      onSubmit={async (values, actions) => {
        await consultantsContact(values);

        setMessage(true);
        setTimeout(() => {
          setMessage(false);
        }, 5000);

        // Reseta os valores do formulário
        actions.resetForm();
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormWrapper canShowMessage={canShowMessage} onSubmit={handleSubmit}>
          <Row spacing="30px">
            <ColLarge>
              <FastField
                label="Nome"
                name="name"
                component={Input}
                placeholder="Digite seu nome"
              />
            </ColLarge>
          </Row>
          <Row>
            <ColMedium>
              <FastField
                mask={PHONE_MASK}
                label="Telefone"
                name="phone"
                placeholder="Digite seu telefone"
                component={InputMasked}
              />
            </ColMedium>
            <ColMedium>
              <FastField
                type="text"
                label="Já possui site"
                name="site"
                placeholder="Digite seu domínio (www) atual"
                component={Input}
              />
            </ColMedium>
          </Row>
          <Row>
            <ColMedium>
              <FastField
                label="Como você deseja ser contactado(a)?"
                name="contact_with"
                component={ButtonGroup}
                options={[
                  { label: "Whatsapp", value: 1 },
                  { label: "Ligação", value: 2 }
                ]}
              />
            </ColMedium>
            <ColMedium>
              <Field
                as="select"
                type="time"
                label="Qual o melhor horário para contato?"
                name="best_time"
                component={Select}
                options={[
                  { label: "09:00", value: "09:00" },
                  { label: "10:00", value: "10:00" },
                  { label: "11:00", value: "11:00" },
                  { label: "12:00", value: "12:00" },
                  { label: "13:00", value: "13:00" },
                  { label: "14:00", value: "14:00" },
                  { label: "15:00", value: "15:00" },
                  { label: "16:00", value: "16:00" },
                  { label: "17:00", value: "17:00" }
                ]}
              />
            </ColMedium>
          </Row>
          <Row>
            <ColLarge style={{ textAlign: "right" }}>
              <Button
                disabled={isSubmitting}
                as="button"
                type="submit"
                kind="solid"
                color="secondary"
              >
                Solicitar Contato
              </Button>
            </ColLarge>
          </Row>
        </FormWrapper>
      )}
    </Formik>
  );
}

FormSpecialConditions.propTypes = {};
FormSpecialConditions.defaultProps = {};

export default FormSpecialConditions;
