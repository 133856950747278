import styled, { css } from "styled-components";
import { Col } from "components/Grid";

export const Wrapper = styled.form`
  position: relative;
  margin: 0 auto 60px;
  width: 100%;
  max-width: 600px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const Content = styled.div`
  transition: 0.3s opacity, 0.3s transform;
  will-change: opacity, transform;

  ${({ hide }) => {
    if (hide) {
      return css`
        opacity: 0;
        transform: scale(0.7);
      `;
    }
  }}
`;

export const ColMedium = styled(Col)`
  @media all and (min-width: 40em) {
    width: 50%;
  }
  @media all and (max-width: 40em) {
    width: 100%;
  }
`;

export const ColLarge = styled(Col)`
  width: 100%;
`;

const messageVisibility = ({ show }) => {
  return show
    ? css`
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      `
    : css`
        visibility: hidden;
        opacity: 0;
        transform: scale(1.1);
      `;
};

export const MessageSuccess = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
  will-change: visibility, opacity, transform;

  ${messageVisibility}

  p {
    line-height: 20px;
    margin-top: 0.5em;
  }

  img {
    margin-bottom: 0.5em;
  }
`;
