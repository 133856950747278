import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  padding: 60px 20px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${props => props.theme.baseWidth};
  margin: 0 auto;
`;
