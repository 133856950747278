import React from "react";
// Components
import Seo from "components/Seo";
import Layout from "components/Layout";
import MainTitle from "components/MainTitle";
import FormSpecialConditions from "components/FormSpecialConditions";
// Sections - Contato
import SectionAddress from "sections/contato/SectionAddress";

export default function CondicoesEspeciais(props) {
  return (
    <Layout {...props}>
      <Seo
        title="Condições especiais - Nossos especialistas em contato"
        description={`Obtenha condições especiais de pagamento.`}
      />
      <MainTitle
        titleTag="h1"
        title="Vamos conversar"
        text="Um de nossos especialistas entrará em contato com você."
      />
      <FormSpecialConditions />
      <SectionAddress />
    </Layout>
  );
}
