import React from "react";
import PropTypes from "prop-types";
// Styles
import { Wrapper, Title, Text } from "./styles";

function MainTitle({
  titleTag = "h2",
  textTag = "p",
  title,
  text,
  color,
  marginBottom,
}) {
  return (
    <Wrapper color={color} marginBottom={marginBottom}>
      <Title as={titleTag}>{title}</Title>
      <Text as={textTag}>{text}</Text>
    </Wrapper>
  );
}

MainTitle.defaultProps = {
  title: null,
  text: null,
  marginBottom: null,
  color: "#2b2b2b",
};

MainTitle.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  marginBottom: PropTypes.string,
  color: PropTypes.string.isRequired,
};

export default MainTitle;
