import styled from "styled-components";

export const Col = styled.div`
  align-self: ${({ alignSelf }) => alignSelf};
`;

export const Row = styled.div.attrs(({ spacing }) => ({
  spacing: spacing || "30px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "wrap"
}))`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ flexWrap }) => flexWrap};

  // Define um espaçamento para as colunas
  margin: 0 ${({ spacing }) => `calc(-${spacing} / 2)`};

  ${Col} {
    // Define um espaçamento para as colunas
    padding: 0 ${({ spacing }) => `calc(${spacing} / 2)`};
  }
`;
