import imageWebp from "images/frame-webp.webp";

/**
 * Retorna se esta rodando o site em um Macbook
 * @returns {boolean}
 */
export const isMac = () => {
  if (typeof navigator === "undefined") return null;
  return navigator.appVersion.indexOf("Mac") !== -1;
};

/**
 * Verifica se o navegador tem suporte a WebP
 * @returns {Promise}
 */
export function hasWebP() {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve();
    };
    img.onerror = function () {
      reject();
    };
    img.src = imageWebp;
  });
}

export const lighthouseDetect = (userAgent) => {
  try {
    return /Chrome-Lighthouse/i.test(userAgent);
  } catch {
    return false;
  }
};
