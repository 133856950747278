import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Content, MessageSuccess } from "./styles";
// Assets
import icon from "./icon-success.svg";

function FormWrapper({ as, children, canShowMessage, message, onSubmit }) {
  return (
    <Wrapper as={as} onSubmit={onSubmit} aria-hidden={canShowMessage}>
      <Content hide={canShowMessage}>{children}</Content>
      <MessageSuccess show={canShowMessage}>
        <div>
          <img src={icon} alt="Icone Sucesso" />
          {message && message()}
        </div>
      </MessageSuccess>
    </Wrapper>
  );
}

FormWrapper.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  canShowMessage: PropTypes.bool,
  message: PropTypes.func
};
FormWrapper.defaultProps = {
  as: "form",
  canShowMessage: false,
  message: () => (
    <>
      <h3>Seu contato foi enviado!</h3>
      <p>
        Um dos nossos especialistas entrará
        <br />
        em contato com você.
      </p>
    </>
  ),
  onSubmit: null
};

export default FormWrapper;
