import React from "react";
import { FaWhatsapp } from "react-icons/fa";
// Styles
import { Content } from "sections/styles";
import { Wrapper, Title, Text, List, Box, BoxPhones, Link } from "./styles";
import { isMac } from "helpers/detect-helpers";
import { shareWhatsapp } from "helpers/share-helpers";

function SectionAddress() {
  const appLink = isMac()
    ? "/programs/anydesk-macOS.dmg"
    : "/programs/anydesk-windows.exe";

  return (
    <Wrapper>
      <Content>
        <Box grow>
          <Title>Atendimento</Title>
          <Text>Dias úteis, 9h até 17h</Text>
        </Box>
        <BoxPhones shrink>
          <Title>Telefones</Title>
          <List>
            <li>(48) 9-9116-1213 (Vivo)</li>
            <li>(48) 9-8814-0223 (Claro)</li>
            <li>(48) 9-9125-1213 (Vivo)</li>
            <li>(48) 9-8846-7888 (Claro)</li>
            <li>(48) 9-9679-1101 (Tim)</li>
            <li>(48) 9-8419-8187 (Oi)</li>
          </List>
        </BoxPhones>
        <Box grow>
          <Title>E-mail Suporte</Title>
          <Link href="mailto:suporte@tecimob.com.br">
            suporte@tecimob.com.br
          </Link>
        </Box>
        <Box grow>
          <Title>Acesso Remoto</Title>
          <Link href={appLink}>Baixar Programa (AnyDesk)</Link>
        </Box>
      </Content>
    </Wrapper>
  );
}

SectionAddress.propTypes = {};
SectionAddress.defaultProps = {};

export default SectionAddress;
